.fade-in {
  opacity: 1;
}

.landingPage {
  font-family: 'Roboto', sans-serif;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobileLandingPage {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.landingContainer {
  width: 1000px;
  height: 500px;
  display: flex;
  flex-direction: row;
  border: none;
}

.mobileLandingContainer {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  border: none;
  justify-content: center;
  align-items: center;
}


.landingContent {
  height: 500px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobileLandingContent {
  height: 600px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landingContent p {
  font-size: 14px;
  text-align: justify;
  width: 350px;
  line-height: 20px;
  color: rgb(62, 62, 62);
}

.mobileLandingContent p {
  font-size: 14px;
  text-align: justify;
  width: 300px;
  line-height: 20px;
  color: rgb(62, 62, 62);
}

.landingContent img {
  width: 350px;
}

.mobileLandingContent img {
  width: 300px;
  margin-top: 50px;
}

.landingContent span {
  color: #20D0B2;
  font-weight: bold;
}

.mobileLandingContent span {
  color: #20D0B2;
  font-weight: bold;
}

/* .contentText {
  margin-top: 50px;
  margin-bottom: 50px;
} */

.landingIcon {
  height: 100%;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.inputContainer {
  width: 350px;
  border-radius: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(46, 70, 91, 0.2);
  box-shadow: 0px 1px 28px -4px rgba(46, 70, 91, 0.4);
  overflow: hidden;
}

.mobileInputContainer {
  width: 300px;
  border-radius: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(46, 70, 91, 0.2);
  box-shadow: 0px 1px 28px -4px rgba(46, 70, 91, 0.4);
  overflow: hidden;
}

.inputContainer input {
  outline: 0;
  border: 0;
  width: 200px;
  margin-bottom: 3px;
  font-size: 14px;
}

.mobileInputContainer input {
  outline: 0;
  border: 0;
  width: 200px;
  margin-bottom: 3px;
  font-size: 14px;
  margin-left: 20px;
}

.icon {
  justify-self:start;
  color: #666666;
  margin: 0 0 0 15px;
  height: 19px;
}

.landingButton {
  border: none;
  background: #20D0B2;
  font-weight: 500;
  box-shadow: 0px 0px 8px 3px rgba(32, 208, 178, 0.4);
  border-radius: 50px;
  color: #ffffff;
  padding: 10px;
  margin-right: 5px;
  transition: ease 0.5s;
}

.mobileLandingButton {
  border: none;
  background: #20D0B2;
  box-shadow: 0px 0px 8px 3px rgba(32, 208, 178, 0.4);
  border-radius: 50px;
  color: #ffffff;
  height: 30px;
  width: 60px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobileLandingButton i {
  color:#ffffff;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.landingButton:hover {
  background: #2E465B;
  box-shadow: 0px 0px 8px 3px rgba(13, 46, 41, 0.4);
  transition: ease 0.5s;
  cursor: pointer;
}

body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}